import clsx from "clsx";
import { DownloadIcon } from "../../../../Icons";
import Button from "../../../../Button";
const JSZip = require("jszip");

export default function DownloadData({ gid, product, buttonType = "icon" }) {
  console.log({product})
  async function downloadData(gid, product) {
    const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;
    const csvEndpoint = `${apiPrefix}/activity/data_annual_csv?product_filter=${product}&gid_value=${gid}`;
    const pngEndpoint = `${apiPrefix}/activity/data_png?gid_value=${gid}`;

    // Fetch CSV data
    const csvResponse = await fetch(csvEndpoint);
    const csvData = await csvResponse.text();

    // Fetch PNG data
    const pngResponse = await fetch(pngEndpoint);
    const pngData = await pngResponse.blob();

    const bucket = "ctrees-website";
    const key = "product/REDD%2BAI.pdf";
    const s3Url = `https://${bucket}.s3.amazonaws.com/${key}`;

    // Fetch PDF data from the public S3 URL
    const fetchPdfData = async () => {
      try {
        const response = await fetch(s3Url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const pdfData = await response.blob();
        return new Blob([pdfData], { type: "application/pdf" });
      } catch (error) {
        console.error("Error fetching PDF from S3:", error);
        throw error;
      }
    };

    const pdfData = await fetchPdfData();

    // Create a zip file
    const zip = new JSZip();
    zip.file(`${gid}_${product}.csv`, csvData);
    zip.file(`${gid}_activity.png`, pngData);
    zip.file(`REDD+AI.pdf`, pdfData);

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Create download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = `${gid}_${product}_data.zip`;

    // Append the link to the body and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  }
  return buttonType === "icon" ? (
    <Button
      backgroundColor={"yellowButton"}
      onClick={() => downloadData(gid, product)}
    >
      <DownloadIcon />
    </Button>
  ) : (
    <button
      onClick={() => downloadData(gid, product)}
      className={buttonWrapperStyle}
    >
      <DownloadIcon />
      <div className="text-black text-sm pl-4">Download full dataset</div>
    </button>
  );
}

const buttonWrapperStyle = clsx(
  `w-fit p-4 rounded-md bg-yellowButtonDefault` +
  ` absolute rounded-lg inline-flex items-center cursor-pointer`
);
